@mixin contain-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@mixin cover-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-row-center {
  display: flex;
  align-items: center;
}
// 圆角大小
$bigBorderRadius: 8px;
$normalBorderRadius: 4px;

// 字体
$fontFamily: HarmonyOS Sans SC;

// 组件大小
$middleHeight: 32px;
$smallHeihgt: 24px;
$large: 40px;

:export {
  bigBorderRadius: $bigBorderRadius;
  normalBorderRadius: $normalBorderRadius;
  fontFamily: $fontFamily;
  middleHeight: $middleHeight;
  smallHeihgt: $smallHeihgt;
  large: $large;
}

.EnergyMonitoringLayout {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .header {
    height: 48px;
    margin: 0 24px;
    padding: 8px 12px;
    background: linear-gradient(
      180deg,
      rgba(104, 155, 255, 0.1) 0%,
      rgba(104, 155, 255, 0) 100%
    );

    border: 1px solid var(--White-border);
    backdrop-filter: blur(8px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
  }

  .item {
    color: var(--White-title);
    padding: 5px 12px;
    margin-right: 16px;
  }

  .line {
    width: 100%;
    border-top: 1px solid var(--White-dividers);
  }

  .breadCrumb {
    padding: 8px 27px;
    line-height: 22px;
    color: var(--White-secondary-text);
    background: var(--White-card);
  }
}

.active {
  .item {
    color: var(--Primary-7);
    background: var(--Primary-3);
    border-radius: 4px;
    transition: 0.2s;
  }
}
.layoutContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--White-card);
  overflow: auto;
}

:global {
  .header-dropdown .ant-dropdown-menu-item:has(.active) {
    background: var(--Primary-4);
  }
  .realtime-monitoring-nav-button {
    &.active {
      span {
        color: var(--Primary-7);
        background: var(--Primary-3);
        border-radius: 4px;
        transition: 0.2s;
      }
      .realtime-monitoring-nav-button-icon {
        color: var(--Primary-7);
      }
    }
    &:hover {
      span {
        background: var(--Primary-3);
        border-radius: 4px;
      }
    }
  }
}
