@mixin contain-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@mixin cover-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-row-center {
  display: flex;
  align-items: center;
}
// 圆角大小
$bigBorderRadius: 8px;
$normalBorderRadius: 4px;

// 字体
$fontFamily: HarmonyOS Sans SC;

// 组件大小
$middleHeight: 32px;
$smallHeihgt: 24px;
$large: 40px;

:export {
  bigBorderRadius: $bigBorderRadius;
  normalBorderRadius: $normalBorderRadius;
  fontFamily: $fontFamily;
  middleHeight: $middleHeight;
  smallHeihgt: $smallHeihgt;
  large: $large;
}

.Login {
  :global {
    .ant-form-item
      .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      content: '';
    }
    .ant-form-item-control-input-content .ant-input {
      background: transparent;
    }
  }
  width: 100vw;
  height: 100vh;
  min-height: 576px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include cover-image();

  @media screen and (max-width: 1366px) {
    background-image: url('./bg.png');
  }

  @media screen and (min-width: 1366px) {
    background-image: url('./bg-large.png');
  }

  .loginForm {
    width: 451px;
    // height: 576px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 100px;
    background: linear-gradient(180deg, rgba(82, 115, 224, 0.31) 0%, rgba(82, 115, 224, 0) 4.19%),
      linear-gradient(180deg, rgba(74, 114, 255, 0.47) 0%, rgba(255, 255, 255, 0) 100%);

    border: 1px solid var(--White-dividers);
    backdrop-filter: blur(15px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: $bigBorderRadius;

    .txt {
      font-weight: 700;
      font-size: 24px;
      color: var(--White-title);
      margin-top: 20px;
    }

    .line {
      width: 311px;
      height: 11px;
      background: linear-gradient(
        270deg,
        rgba(24, 144, 255, 0) -0.24%,
        rgba(24, 144, 255, 0.8) 49.24%,
        rgba(24, 144, 255, 0) 99.76%
      );
      backdrop-filter: blur(4px);
      margin-bottom: 32px;
    }

    // .label {
    //   font-weight: 400;
    //   font-size: 14px;
    //   line-height: 22px;
    //   color: rgba(255, 255, 255, 0.85);
    // }

    img {
      margin-left: 16px;
      cursor: pointer;
      border-radius: 4px;
    }
  }
}
// .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
//   content: '';
// }
