@mixin contain-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@mixin cover-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-row-center {
  display: flex;
  align-items: center;
}
// 圆角大小
$bigBorderRadius: 8px;
$normalBorderRadius: 4px;

// 字体
$fontFamily: HarmonyOS Sans SC;

// 组件大小
$middleHeight: 32px;
$smallHeihgt: 24px;
$large: 40px;

:export {
  bigBorderRadius: $bigBorderRadius;
  normalBorderRadius: $normalBorderRadius;
  fontFamily: $fontFamily;
  middleHeight: $middleHeight;
  smallHeihgt: $smallHeihgt;
  large: $large;
}

$--function-info: #5273e0;
$--function-success: #49aa19;
$--function-warning: #d89614;
$--function-error: #d32029;

$--background-primary-bg: #151428;
$--background-popover-bg: #2f2e43;
$--background-card-bg: #28273a;

$--Primary-1: #131629;
$--Primary-2: #161d40;
$--Primary-3: #1c2755;
$--Primary-4: #203175;
$--Primary-5: #263ea0;
$--Primary-6: #2b4acb;
$--Primary-7: #5273e0;
$--Primary-8: #7f9ef3;
$--Primary-9: #a8c1f8;
$--Primary-10: #d2e0fa;

$--White-title: rgba(255, 255, 255, 0.85);
$--White-primary-text: rgba(255, 255, 255, 0.75);
$--White-secondary-text: rgba(255, 255, 255, 0.45);
$--White-disable: rgba(255, 255, 255, 0.3);
$--White-border: rgba(255, 255, 255, 0.2);
$--White-dividers: rgba(255, 255, 255, 0.12);
$--White-hover: rgba(255, 255, 255, 0.08);
$--White-card: rgba(255, 255, 255, 0.04);
$--White-table-header: rgba(255, 255, 255, 0.04);

$--Cyab-1: #112123;
$--Cyab-2: #113536;
$--Cyab-3: #144848;
$--Cyab-4: #146262;
$--Cyab-5: #138585;
$--Cyab-6: #13a8a8;
$--Cyab-7: #33bcb7;
$--Cyab-8: #58d1c9;
$--Cyab-9: #84e2d8;
$--Cyab-10: #b2f1e8;

$--Dust-Red-1: #2a1215;
$--Dust-Red-2: #431418;
$--Dust-Red-3: #58181c;
$--Dust-Red-4: #791a1f;
$--Dust-Red-5: #a61d24;
$--Dust-Red-6: #d32029;
$--Dust-Red-7: #e84749;
$--Dust-Red-8: #f37370;
$--Dust-Red-9: #f89f9a;
$--Dust-Red-10: #fac8c3;

$--Sunset-Orange-1: #2b1d11;
$--Sunset-Orange-2: #442a11;
$--Sunset-Orange-3: #593815;
$--Sunset-Orange-4: #7c4a15;
$--Sunset-Orange-5: #aa6215;
$--Sunset-Orange-6: #d87a16;
$--Sunset-Orange-7: #e89a3c;
$--Sunset-Orange-8: #f3b765;
$--Sunset-Orange-9: #f8cf8d;
$--Sunset-Orange-10: #fae3b7;

$--Daybreak-Blue-1: #111d2c;
$--Daybreak-Blue-2: #112a45;
$--Daybreak-Blue-3: #15395b;
$--Daybreak-Blue-4: #164c7e;
$--Daybreak-Blue-5: #1765ad;
$--Daybreak-Blue-6: #177ddc;
$--Daybreak-Blue-7: #3c9ae8;
$--Daybreak-Blue-8: #65b7f3;
$--Daybreak-Blue-9: #8dcff8;
$--Daybreak-Blue-10: #b7e3fa;

$--Sunrise-Yellow-1: #2b2611;
$--Sunrise-Yellow-2: #443b11;
$--Sunrise-Yellow-3: #595014;
$--Sunrise-Yellow-4: #7c6e14;
$--Sunrise-Yellow-5: #aa9514;
$--Sunrise-Yellow-6: #d8bd14;
$--Sunrise-Yellow-7: #e8d639;
$--Sunrise-Yellow-8: #f3ea62;
$--Sunrise-Yellow-9: #f8f48b;
$--Sunrise-Yellow-10: #fafab5;

$--Polar-Green-1: #162312;
$--Polar-Green-2: #1d3712;
$--Polar-Green-3: #274916;
$--Polar-Green-4: #306317;
$--Polar-Green-5: #3c8618;
$--Polar-Green-6: #49aa19;
$--Polar-Green-7: #6abe39;
$--Polar-Green-8: #8fd460;
$--Polar-Green-9: #b2e58b;
$--Polar-Green-10: #d5f2bb;

// $--transparen: rgba(0, 0, 0, 0.5);
$--transparen: --background-card-bg;

:root {
  --function-info: #5273e0;
  --function-success: #49aa19;
  --function-warning: #d89614;
  --function-error: #d32029;

  --background-primary-bg: #151428;
  --background-popover-bg: #2f2e43;
  --background-card-bg: #28273a;

  --Primary-1: #131629;
  --Primary-2: #161d40;
  --Primary-3: #1c2755;
  --Primary-4: #203175;
  --Primary-5: #263ea0;
  --Primary-6: #2b4acb;
  --Primary-7: #5273e0;
  --Primary-8: #7f9ef3;
  --Primary-9: #a8c1f8;
  --Primary-10: #d2e0fa;

  --White-title: rgba(255, 255, 255, 0.85);
  --White-primary-text: rgba(255, 255, 255, 0.75);
  --White-secondary-text: rgba(255, 255, 255, 0.45);
  --White-disable: rgba(255, 255, 255, 0.3);
  --White-border: rgba(255, 255, 255, 0.2);
  --White-dividers: rgba(255, 255, 255, 0.12);
  --White-hover: rgba(255, 255, 255, 0.08);
  --White-card: rgba(255, 255, 255, 0.04);
  --White-table-header: rgba(255, 255, 255, 0.04);

  --Cyab-1: #112123;
  --Cyab-2: #113536;
  --Cyab-3: #144848;
  --Cyab-4: #146262;
  --Cyab-5: #138585;
  --Cyab-6: #13a8a8;
  --Cyab-7: #33bcb7;
  --Cyab-8: #58d1c9;
  --Cyab-9: #84e2d8;
  --Cyab-10: #b2f1e8;

  --Dust-Red-1: #2a1215;
  --Dust-Red-2: #431418;
  --Dust-Red-3: #58181c;
  --Dust-Red-4: #791a1f;
  --Dust-Red-5: #a61d24;
  --Dust-Red-6: #d32029;
  --Dust-Red-7: #e84749;
  --Dust-Red-8: #f37370;
  --Dust-Red-9: #f89f9a;
  --Dust-Red-10: #fac8c3;

  --Sunset-Orange-1: #2b1d11;
  --Sunset-Orange-2: #442a11;
  --Sunset-Orange-3: #593815;
  --Sunset-Orange-4: #7c4a15;
  --Sunset-Orange-5: #aa6215;
  --Sunset-Orange-6: #d87a16;
  --Sunset-Orange-7: #e89a3c;
  --Sunset-Orange-8: #f3b765;
  --Sunset-Orange-9: #f8cf8d;
  --Sunset-Orange-10: #fae3b7;

  --Daybreak-Blue-1: #111d2c;
  --Daybreak-Blue-2: #112a45;
  --Daybreak-Blue-3: #15395b;
  --Daybreak-Blue-4: #164c7e;
  --Daybreak-Blue-5: #1765ad;
  --Daybreak-Blue-6: #177ddc;
  --Daybreak-Blue-7: #3c9ae8;
  --Daybreak-Blue-8: #65b7f3;
  --Daybreak-Blue-9: #8dcff8;
  --Daybreak-Blue-10: #b7e3fa;

  --Sunrise-Yellow-1: #2b2611;
  --Sunrise-Yellow-2: #443b11;
  --Sunrise-Yellow-3: #595014;
  --Sunrise-Yellow-4: #7c6e14;
  --Sunrise-Yellow-5: #aa9514;
  --Sunrise-Yellow-6: #d8bd14;
  --Sunrise-Yellow-7: #e8d639;
  --Sunrise-Yellow-8: #f3ea62;
  --Sunrise-Yellow-9: #f8f48b;
  --Sunrise-Yellow-10: #fafab5;

  --Polar-Green-1: #162312;
  --Polar-Green-2: #1d3712;
  --Polar-Green-3: #274916;
  --Polar-Green-4: #306317;
  --Polar-Green-5: #3c8618;
  --Polar-Green-6: #49aa19;
  --Polar-Green-7: #6abe39;
  --Polar-Green-8: #8fd460;
  --Polar-Green-9: #b2e58b;
  --Polar-Green-10: #d5f2bb;

  --transparent: rgba(0, 0, 0, 0);
}

:export {
  functionInfo: $--function-info;
  functionSuccess: $--function-success;
  functionWarning: $--function-warning;
  functionError: $--function-error;
  backgroundPrimaryBg: $--background-primary-bg;
  backgroundPopoverBg: $--background-popover-bg;
  backgroundCardBg: $--background-card-bg;
  primary_1: $--Primary-1;
  primary_2: $--Primary-2;
  primary_3: $--Primary-3;
  primary_4: $--Primary-4;
  primary_5: $--Primary-5;
  primary_6: $--Primary-6;
  primary_7: $--Primary-7;
  primary_8: $--Primary-8;
  primary_9: $--Primary-9;
  primary_10: $--Primary-10;
  whiteTitle: $--White-title;
  whitePrimaryText: $--White-primary-text;
  whiteSecondaryText: $--White-secondary-text;
  whiteDisable: $--White-disable;
  whiteBorder: $--White-border;
  whiteDividers: $--White-dividers;
  whiteHover: $--White-hover;
  whiteCard: $--White-card;
  whiteTableHeader: $--White-table-header;
  cyab_1: $--Cyab-1;
  cyab_2: $--Cyab-2;
  cyab_3: $--Cyab-3;
  cyab_4: $--Cyab-4;
  cyab_5: $--Cyab-5;
  cyab_6: $--Cyab-6;
  cyab_7: $--Cyab-7;
  cyab_8: $--Cyab-8;
  cyab_9: $--Cyab-9;
  cyab_10: $--Cyab-10;
  dustRed_1: $--Dust-Red-1;
  dustRed_2: $--Dust-Red-2;
  dustRed_3: $--Dust-Red-3;
  dustRed_4: $--Dust-Red-4;
  dustRed_5: $--Dust-Red-5;
  dustRed_6: $--Dust-Red-6;
  dustRed_7: $--Dust-Red-7;
  dustRed_8: $--Dust-Red-8;
  dustRed_9: $--Dust-Red-9;
  dustRed_10: $--Dust-Red-10;
  sunsetOrange_1: $--Sunset-Orange-1;
  sunsetOrange_2: $--Sunset-Orange-2;
  sunsetOrange_3: $--Sunset-Orange-3;
  sunsetOrange_4: $--Sunset-Orange-4;
  sunsetOrange_5: $--Sunset-Orange-5;
  sunsetOrange_6: $--Sunset-Orange-6;
  sunsetOrange_7: $--Sunset-Orange-7;
  sunsetOrange_8: $--Sunset-Orange-8;
  sunsetOrange_9: $--Sunset-Orange-9;
  sunsetOrange_10: $--Sunset-Orange-10;
  daybreakBlue_1: $--Daybreak-Blue-1;
  daybreakBlue_2: $--Daybreak-Blue-2;
  daybreakBlue_3: $--Daybreak-Blue-3;
  daybreakBlue_4: $--Daybreak-Blue-4;
  daybreakBlue_5: $--Daybreak-Blue-5;
  daybreakBlue_6: $--Daybreak-Blue-6;
  daybreakBlue_7: $--Daybreak-Blue-7;
  daybreakBlue_8: $--Daybreak-Blue-8;
  daybreakBlue_9: $--Daybreak-Blue-9;
  daybreakBlue_10: $--Daybreak-Blue-10;
  sunriseYellow_1: $--Sunrise-Yellow-1;
  sunriseYellow_2: $--Sunrise-Yellow-2;
  sunriseYellow_3: $--Sunrise-Yellow-3;
  sunriseYellow_4: $--Sunrise-Yellow-4;
  sunriseYellow_5: $--Sunrise-Yellow-5;
  sunriseYellow_6: $--Sunrise-Yellow-6;
  sunriseYellow_7: $--Sunrise-Yellow-7;
  sunriseYellow_8: $--Sunrise-Yellow-8;
  sunriseYellow_9: $--Sunrise-Yellow-9;
  sunriseYellow_10: $--Sunrise-Yellow-10;
  polarGreen_1: $--Polar-Green-1;
  polarGreen_2: $--Polar-Green-2;
  polarGreen_3: $--Polar-Green-3;
  polarGreen_4: $--Polar-Green-4;
  polarGreen_5: $--Polar-Green-5;
  polarGreen_6: $--Polar-Green-6;
  polarGreen_7: $--Polar-Green-7;
  polarGreen_8: $--Polar-Green-8;
  polarGreen_9: $--Polar-Green-9;
  polarGreen_10: $--Polar-Green-10;
  // transparent: $--transparen;
  transparent: $--background-card-bg;
}
