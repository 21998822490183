@mixin contain-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@mixin cover-image() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin align-row-center {
  display: flex;
  align-items: center;
}
// 圆角大小
$bigBorderRadius: 8px;
$normalBorderRadius: 4px;

// 字体
$fontFamily: HarmonyOS Sans SC;

// 组件大小
$middleHeight: 32px;
$smallHeihgt: 24px;
$large: 40px;

:export {
  bigBorderRadius: $bigBorderRadius;
  normalBorderRadius: $normalBorderRadius;
  fontFamily: $fontFamily;
  middleHeight: $middleHeight;
  smallHeihgt: $smallHeihgt;
  large: $large;
}

.NormalLayout {
  width: 100vw;
  height: 100vh;
  background-color: var(--Primary-1);
  color: var(--White-title);

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 16px 24px;
    height: 56px;
    color: var(--White-title);
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .energy {
    color: var(--White-title);
    margin: 0 41px 0 4px;
  }
  .management {
    color: var(--White-title);
    margin-left: 4px;
  }
  .underline {
    .energy,
    .managemen {
      position: relative;
      z-index: 1;
    }
    .energy:after,
    .managemen:after {
      display: inline-block;
      content: ' ';
      width: 76px;
      height: 8px;
      background: #2b4acb;
      border-radius: 10px;
      position: absolute;
      top: 11px;
      left: -18px;
      z-index: -1;
    }
  }
  .item {
    color: var(--White-title);
    padding: 5px 12px;
    margin-right: 16px;
  }
}

.layoutContent {
  height: calc(100% - 61px);
  display: flex;
  flex-direction: column;
}

.userCenter {
  display: flex;
  align-items: center;
}

.loading {
  width: 100%;
  height: 100%;
}
